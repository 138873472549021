import React, { useEffect, useContext } from "react";
import { View, Text } from "react-native";

import firebase from "firebase/app";

import axios from "../api/axios";

import { Context } from "../hocs/store";

import { styles, strings } from "../resources";

import { useQuery } from "../hooks/useQuery";

export default function AdminAppleCallbackScreen() {
  let query = useQuery();
  const [state, dispatch] = useContext(Context);

  useEffect(() => {
    const code = query.get("code");

    async function registerWithApple() {
      if (!code) {
        window.open(`${window.origin}/sign-in`, "_self");
        return;
      }

      const registerResult = await axios.post("/apple-callback", {
        code,
      });

      if (registerResult.data.statusCode === 200) {
        const { me, token } = registerResult.data;

        dispatch({ type: "SET_ME", payload: me });
        const authResult = await firebase.auth().signInWithCustomToken(token);

        var user = authResult.user;
        if (!user) {
          window.open(`${window.origin}/error`, "_self");
          return;
        }

        window.open(`${window.origin}/school-code`, "_self");
      }
    }

    registerWithApple();
  }, []);

  return (
    <View style={styles.container}>
      <View style={styles.headerSection}>
        <Text style={styles.largeTitle}>{strings.coachx}</Text>
      </View>
    </View>
  );
}
