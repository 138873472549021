import React, { useState, useContext, useEffect } from "react";
import { View, Text, TextInput, TouchableOpacity } from "react-native";

import ls from "local-storage";

import axios from "../api/axios";
import firebase from "firebase/app";

import { Context } from "../hocs/store";

import { styles, strings } from "../resources";

export default function SchoolCodeScreen({ navigation }) {
  const [state, dispatch] = useContext(Context);
  const [code, setCode] = useState(null);

  async function onSubmit() {
    if (!code) {
      alert(strings.schoolCodeAlert);
    }

    if (!firebase.auth().currentUser) {
      navigation.navigate("Login");
    }

    dispatch({ type: "SET_CODE", payload: code });

    let fid = firebase.auth().currentUser.uid;

    let linkResponse = await axios.post("/admin-link-school", {
      fid,
      cd: code,
    });

    if (linkResponse.data.statusCode === 200) {
      let { school, me } = linkResponse.data;
      dispatch({ type: "SET_ME", me });
      dispatch({ type: "SET_SCHOOL", school });

      ls.set("CODE", code);
      ls.set("ME", me._id);
      ls.set("SCHOOL", school._id);

      navigation.navigate("Admin", { processing: true });
    } else {
      navigation.navigate("Error");
    }
  }

  return (
    <View style={styles.container}>
      <View style={styles.headerSection}>
        <Text style={styles.largeTitle}>{strings.coachx}</Text>
      </View>
      <View style={styles.loginSection}>
        <Text style={styles.body}>配布されたコードを入力してください。</Text>
        <TextInput
          style={styles.border}
          onChangeText={setCode}
          placeholder={strings.schoolCodeField}
        />
        <TouchableOpacity style={styles.button} onPress={onSubmit}>
          <Text style={styles.buttonText}>{strings.submit}</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
}
