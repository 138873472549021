export const credentials = {
  apiKey: "AIzaSyCrNECBx_hq7W_wAVcKPBCegRQ_iNFzQts",
  authDomain: "pestalozzi-onlinecoaching.firebaseapp.com",
  databaseURL: "https://pestalozzi-onlinecoaching.firebaseio.com",
  projectId: "pestalozzi-onlinecoaching",
  storageBucket: "pestalozzi-onlinecoaching.appspot.com",
  messagingSenderId: "254333701900",
  appId: "1:254333701900:web:b915d3a07f9ab196348329",
  measurementId: "G-MJKCH1B49N",
};
