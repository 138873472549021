import { StyleSheet, Dimensions } from "react-native";
import { colors } from "./colors";

const { width, heihgt } = Dimensions.get("window");

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
    alignItems: "center",
  },
  coloredContainer: {
    flex: 1,
    backgroundColor: colors.lightGray,
    alignItems: "center",
  },
  centerContainer: {
    flex: 1,
    backgroundColor: colors.white,
    alignItems: "center",
    justifyContent: "center",
  },
  largeTitle: {
    fontSize: width > 720 ? 28 : 21,
    fontWeight: "700",
    padding: 5,
  },
  title: {
    fontSize: width > 720 ? 24 : 17,
    fontWeight: "700",
    padding: 5,
  },
  body: {
    fontSize: 17,
    fontWeight: "400",
    paddingVertical: 5,
  },
  bodyBold: {
    fontSize: 17,
    fontWeight: "700",
    paddingVertical: 5,
  },
  headerSection: {
    flexDirection: "row",
    justifyContent: "space-between",
    width,
    paddingHorizontal: 40,
    paddingVertical: 20,
    marginBottom: 20,
    backgroundColor: colors.white,
  },
  loginSection: {
    justifyContent: "space-between",
    width,
    marginTop: 100,
    alignItems: "center",
  },
  button: {
    paddingVertical: 10,
    paddingHorizontal: 10,
    backgroundColor: colors.blue,
    borderRadius: 5,
    width: 200,
  },
  largeButton: {
    paddingVertical: 10,
    paddingHorizontal: 10,
    backgroundColor: colors.blue,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 10,
    width: 280,
    height: 60,
    marginVertical: 10,
  },
  smallButton: {
    paddingVertical: 10,
    paddingHorizontal: 10,
    backgroundColor: colors.blue,
    borderRadius: 5,
    width: 100,
  },
  buttonText: {
    fontSize: 17,
    color: colors.white,
    fontWeight: "700",
    textAlign: "center",
  },
  largeButtonText: {
    fontSize: 21,
    color: colors.white,
    fontWeight: "700",
    textAlign: "center",
  },
  border: {
    width: Math.max(Math.min(1080, width) - 200, 375),
    margin: 10,
    padding: 15,
    fontSize: 16,
    borderColor: colors.lightGray,
    borderWidth: 1,
  },
  dashboard: {
    paddingTop: width > 720 ? 50 : 20,
    paddingHorizontal: width > 720 ? 50 : 20,
    width: width > 720 ? Math.min(1080, width) - 200 : width - 20,
    marginHorizontal: width > 720 ? 100 : 0,
    marginTop: width > 720 ? 50 : 20,
    backgroundColor: colors.white,
    borderRadius: 15,
  },
  underlinedRow: {
    borderBottomWidth: 1,
    borderBottomColor: colors.darkGray,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
});
