import React, { useContext } from "react";
import { Text, View, TouchableOpacity } from "react-native";
import firebase from "firebase/app";

import ls from "local-storage";

import { Context } from "../hocs/store";

import { styles } from "../resources";

export default function ErrorScreen({ navigation }) {
  const [state, dispatch] = useContext(Context);

  async function onSignOut() {
    try {
      await firebase.auth().signOut();
    } catch {}
    ls.clear();
    navigation.navigate("Login");
  }

  return (
    <View style={styles.centerContainer}>
      <Text style={styles.largeTitle}>
        {state.error || "エラーがありました。"}
      </Text>
      <Text>担当者にお問い合わせください。</Text>
      <TouchableOpacity style={styles.button} onPress={onSignOut}>
        <Text style={styles.buttonText}>サインアウト</Text>
      </TouchableOpacity>
    </View>
  );
}
