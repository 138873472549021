import axios from "../api/axios";

export async function fetchMe({ dispatch, uid, fid }) {
  try {
    let meResult = await axios.get("/me", {
      params: {
        uid,
        fid,
      },
    });
    dispatch({ type: "SET_ME", payload: meResult.data.me });
  } catch (e) {
    console.log("🚀 error:", e);
  }
}
