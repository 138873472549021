import axios from "../api/axios";

export async function fetchSchool({ dispatch, sid, cd }) {
  try {
    let schoolResult = await axios.get("/school", {
      params: {
        sid,
      },
    });
    dispatch({
      type: "SET_SCHOOL",
      payload: schoolResult.data.school,
    });
  } catch (e) {
    console.log("🚀 error:", e);
  }
}
