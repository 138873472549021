import React, { useContext, useEffect, useState } from "react";
import { Text, View, TouchableOpacity, Clipboard } from "react-native";

import firebase from "firebase/app";
import ls from "local-storage";
import moment from "moment";

import { getStripe, createCheckoutIntent } from "../utils/stripe";

import { styles, strings, colors } from "../resources";
import { Context } from "../hocs/store";

import axios from "../api/axios";

import { fetchMe } from "../actions/me";
import { fetchSchool } from "../actions/school";

export default function AdminScreen({ navigation, route }) {
  const [state, dispatch] = useContext(Context);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(async () => {
      if (!firebase.auth().currentUser) {
        navigation.navigate("Login");
      } else {
        const currentUser = firebase.auth().currentUser;

        let me = ls.get("ME");
        if (me) {
          await fetchMe({ dispatch, uid: me, fid: currentUser.uid });
        }

        let school = ls.get("SCHOOL");
        let code = ls.get("CODE");
        if (school && code) {
          await fetchSchool({
            dispatch,
            sid: school,
            cd: code,
          });
        }

        setLoading(false);
        console.log("fetching");
      }
    }, 1500);
  }, []);

  useEffect(() => {
    async function fetch() {
      if (state.school && state.school.attributes.checkoutSessionId) {
        let ssid = state.school.attributes.checkoutSessionId;

        if (state.school.attributes.paymentCompleted) {
          return;
        }

        await axios.get(`/checkout-session/${ssid}`, {
          params: {
            uid: state.me._id,
            fid: firebase.auth().currentUser.uid,
            sid: state.school._id,
          },
        });
      }
    }
    if (!loading) {
      fetch();
    }
  }, [loading]);

  async function onSignOut() {
    try {
      ls.clear();
      await firebase.auth().signOut();
    } catch (e) {
      console.log("🚀 error:", e);
    }
    navigation.navigate("Login");
  }

  async function onPay() {
    const amount = state.school?.attributes.amount;

    const checkout = await createCheckoutIntent({
      name: state.school.attributes.name,
      amount,
      successUrl: "https://app.coachxapp.com/admin?processing=true",
      cancelUrl: "https://app.coachxapp.com/admin",
    });

    const stripe = await getStripe();

    const { error } = await stripe.redirectToCheckout({
      sessionId: checkout.paymentIntent.id,
    });

    if (error) {
      alert(error);
    }
  }

  function onCopy() {
    let shareUrl = `https://app.coachxapp.com/school?sid=${state.school._id}`;
    Clipboard.setString(shareUrl);
    alert("URLをコピーしました。");
  }

  let validUntil = moment(state.school?.attributes.subscriptionValidUntil)
    .utc()
    .format("LL");

  console.log("🚀 route:", route);
  if (route.params && route.params.processing == true) {
    setTimeout(() => {
      console.log("redirecting");
      window.open(`${window.origin}/admin`, "_self");
    }, 1000);
  }

  return (
    <View style={styles.coloredContainer}>
      <View style={styles.headerSection}>
        <Text style={styles.largeTitle}>{strings.coachx}</Text>
        <TouchableOpacity style={styles.button} onPress={onSignOut}>
          <Text style={styles.buttonText}>{strings.signOut}</Text>
        </TouchableOpacity>
      </View>
      <View style={styles.dashboard}>
        <View style={styles.underlinedRow}>
          <Text style={styles.title}>学校・大学・団体:</Text>
          <Text style={styles.title}>{state.school?.attributes.name}</Text>
        </View>

        <View style={{ paddingVertical: 20 }}>
          <View>
            <View style={styles.row}>
              <Text style={styles.bodyBold}>アカウント数:</Text>
              <Text style={styles.body}>
                {state.school?.attributes.accounts}
              </Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.bodyBold}>期限:</Text>
              <Text style={styles.body}>{validUntil}</Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.bodyBold}>申請状況:</Text>
              <Text style={styles.body}>{`${
                state.school?.attributes.paymentCompleted
                  ? "申請済み"
                  : "未申請"
              }`}</Text>
            </View>
          </View>

          {state.school && state.school.attributes.paymentCompleted && (
            <View
              style={{
                alignItems: "center",
                justifyContent: "space-evenly",
                borderColor: colors.black,
                borderWidth: 1,
                marginVertical: 20,
                paddingVertical: 10,
                flexDirection: "row",
              }}
            >
              <Text style={styles.body}>
                https://app.coachxapp.com/school?sid={state.school._id}
              </Text>
              <TouchableOpacity style={styles.smallButton} onPress={onCopy}>
                <Text style={styles.buttonText}>{strings.copy}</Text>
              </TouchableOpacity>
            </View>
          )}

          {route.params && route.params.processing === true ? (
            <View
              style={{
                marginTop: 40,
                marginBottom: 20,
                alignItems: "center",
              }}
            >
              <TouchableOpacity style={styles.button}>
                <Text style={styles.buttonText}>{strings.processing}</Text>
              </TouchableOpacity>
            </View>
          ) : (
            state.school &&
            !state.school.attributes.paymentCompleted && (
              <View
                style={{
                  marginTop: 40,
                  marginBottom: 20,
                  alignItems: "center",
                }}
              >
                {state.school &&
                  state.school.attributes.paymentCompleted === false && (
                    <TouchableOpacity style={styles.button} onPress={onPay}>
                      <Text style={styles.buttonText}>{strings.pay}</Text>
                    </TouchableOpacity>
                  )}
              </View>
            )
          )}
        </View>
      </View>
    </View>
  );
}
