import React, { useContext, useEffect } from "react";

import { Text, View } from "react-native";

import { styles, strings } from "../resources";

import firebase from "firebase/app";
import { Context } from "../hocs/store";

import ls from "local-storage";

import axios from "../api/axios";

export default function SplashScreen({ navigation }) {
  const [state, dispatch] = useContext(Context);

  useEffect(() => {
    setTimeout(async () => {
      const currentUser = firebase.auth().currentUser;
      if (currentUser) {
        let me = ls.get("ME");
        if (me && !state.me) {
          try {
            let meResult = await axios.get("/me", {
              params: {
                uid: me,
                fid: currentUser.uid,
              },
            });
            dispatch({ type: "SET_ME", payload: meResult.data.me });
          } catch (e) {
            console.log("🚀 error:", e);
          }
        }
        let school = ls.get("SCHOOL");
        let code = ls.get("CODE");
        if (school && code && !state.school) {
          try {
            let schoolResult = await axios.get("/school", {
              params: {
                sid: school,
                cd: code,
              },
            });
            dispatch({
              type: "SET_SCHOOL",
              payload: schoolResult.data.school,
            });
          } catch (e) {
            console.log("🚀 error:", e);
          }
        }

        navigation.navigate("Admin");
      } else {
        navigation.navigate("Login");
      }
    }, 1000);
  }, []);

  return (
    <View style={styles.centerContainer}>
      <Text>{strings.loading}</Text>
    </View>
  );
}
