export const strings = {
  coachx: "CoachX",
  submit: "次へ",
  googleSignIn: "Googleでサインイン",
  googleRegister: "Googleで登録",
  copy: "コピー",
  loading: "ロード中...",
  processing: "処理中...",
  schoolCodeField: "ログインコード",
  schoolCodeAlert: "ログインコードを入力してください。",
  signOut: "サインアウト",
  pay: "申請する",
};
