import React, { useEffect, useState } from "react";
import { View, Text, TouchableOpacity } from "react-native";

// import { Video } from "expo-av";

import firebase from "firebase/app";

import axios from "../api/axios";

import { styles, strings, links, colors, errors } from "../resources";

export default function School({ navigation, route }) {
  const [school, setSchool] = useState(null);

  useEffect(() => {
    async function fetch() {
      try {
        const schoolResult = await axios.get("/school", {
          params: { sid: route.params.sid },
        });

        if (schoolResult.data.statusCode === 200) {
          setSchool(schoolResult.data.school);
        }
      } catch (e) {
        console.log(e.message);
      }
    }

    fetch();
  }, []);

  async function onGoogleSignIn() {
    const provider = new firebase.auth.GoogleAuthProvider();

    try {
      const authResult = await firebase.auth().signInWithPopup(provider);

      var user = authResult.user;

      const dbResult = await axios.post("/link-school", {
        fid: firebase.auth().currentUser.uid,
        email: firebase.auth().currentUser.email,
        sid: route.params.sid,
      });

      return navigation.navigate("Download");
    } catch (error) {
      console.log("🚀 error", error);
      return navigation.navigate("Download");
    }
  }

  return (
    <View style={styles.container}>
      <View style={styles.headerSection}>
        <Text style={styles.largeTitle}>{strings.coachx}</Text>
      </View>
      <View style={styles.dashboard}>
        <View style={styles.underlinedRow}>
          <Text style={styles.title}>学校・大学・団体:</Text>
          <Text style={styles.title}>{school?.attributes.name}</Text>
        </View>

        {school && (
          <View style={{ alignItems: "center", marginTop: 50 }}>
            {/* <Video
              source={{
                uri: links.promo,
              }}
              rate={1.0}
              volume={1.0}
              isMuted={true}
              resizeMode="cover"
              shouldPlay
              isLooping
              style={{
                width: 500,
                height: 270,
                backgroundColor: colors.darkGray,
                marginBottom: 50,
              }}
            /> */}
            <View style={{ alignItems: "center" }}>
              <TouchableOpacity style={styles.button} onPress={onGoogleSignIn}>
                <Text style={styles.buttonText}>{strings.googleRegister}</Text>
              </TouchableOpacity>
            </View>
          </View>
        )}
      </View>
    </View>
  );
}
