import { Platform } from "react-native";
import ls from "local-storage";

const Reducer = (state, action) => {
  switch (action.type) {
    case "SET_ME":
      if (Platform.OS === "web") {
        ls.set("ME", action.payload?._id);
      }
      return { ...state, me: action.payload };

    case "SET_SCHOOL":
      if (Platform.OS === "web") {
        ls.set("SCHOOL", action.payload?._id);
      }
      return { ...state, school: action.payload };

    case "SET_CODE":
      if (Platform.OS === "web") {
        ls.set("CODE", action.payload?.code);
      }
      return { ...state, code: action.payload };

    case "SET_ERROR":
      return { ...state, error: action.payload };

    default:
      return state;
  }
};

export default Reducer;
