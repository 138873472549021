import React from "react";

import firebase from "firebase/app";
import "firebase/auth";

import { credentials } from "./config/firebase";

import AppNavigator from "./navigation/AppNavigator";
import Store from "./hocs/store";

import { Elements } from "@stripe/react-stripe-js";

import { getStripe } from "./utils/stripe";

function setupFirebase() {
  console.log("🚀 set up firebase");
  if (firebase.apps.filter((app) => app.name === "[DEFAULT]").length === 0) {
    firebase.initializeApp(credentials);
    firebase.auth().languageCode = "ja";
  }
}
setupFirebase();

export default function App() {
  return (
    <Elements stripe={getStripe()}>
      <Store>
        <AppNavigator />
      </Store>
    </Elements>
  );
}
