import { loadStripe } from "@stripe/stripe-js";
import axios from "../api/axios";

import { credentials } from "../config/stripe";

import firebase from "firebase/app";

import ls from "local-storage";

let stripePromise;

export function getStripe() {
  if (!stripePromise) {
    stripePromise = loadStripe(credentials.publishableKey);
  }
  return stripePromise;
}

export async function createCheckoutIntent({
  name,
  amount,
  successUrl,
  cancelUrl,
}) {
  const fid = firebase.auth().currentUser.uid;
  const sid = ls.get("SCHOOL");
  const uid = ls.get("ME");

  const response = await axios.post("/checkout-session", {
    name,
    amount,
    successUrl,
    cancelUrl,
    fid,
    uid,
    sid,
  });
  return response.data;
}
