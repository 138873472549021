import React, { useContext } from "react";
import { Text, View, TouchableOpacity } from "react-native";

import firebase from "firebase/app";

import { Video } from "expo-av";

import axios from "../api/axios";

import { Context } from "../hocs/store";

import { styles, strings, links, colors, errors } from "../resources";

import AppleLogin from "react-apple-login";

export default function LoginScreen({ navigation }) {
  const [state, dispatch] = useContext(Context);

  async function onGoogleSignIn() {
    const provider = new firebase.auth.GoogleAuthProvider();

    try {
      const authResult = await firebase.auth().signInWithPopup(provider);

      var user = authResult.user;

      if (!user) {
        navigation.navigate("Error");
        return;
      }

      const dbResult = await axios.post("/sign-in", {
        fid: firebase.auth().currentUser.uid,
        email: firebase.auth().currentUser.email,
      });

      if (dbResult.statusCode === 404) {
        dispatch({
          type: "SET_ERROR",
          payload: { message: errors.codeNotFound },
        });
        return navigation.navigate("Error");
      } else {
        let { me } = dbResult.data;
        dispatch({ type: "SET_ME", payload: me });
        return navigation.navigate("SchoolCode");
      }
    } catch (error) {
      console.log("🚀 error", error);
      navigation.navigate("Error");
    }
  }

  return (
    <View style={styles.container}>
      <View style={styles.headerSection}>
        <Text style={styles.largeTitle}>{strings.coachx}</Text>
      </View>
      <View style={styles.loginSection}>
        <Video
          source={{
            uri: links.promo,
          }}
          rate={1.0}
          volume={1.0}
          isMuted={true}
          resizeMode="cover"
          shouldPlay
          isLooping
          style={{
            width: 500,
            height: 300,
            backgroundColor: colors.darkGray,
            marginBottom: 50,
          }}
        />
        <View style={{ alignItems: "center" }}>
          <Text style={styles.body}>
            まずはお好みの方法でサインインしてください。
          </Text>
          <TouchableOpacity style={styles.largeButton} onPress={onGoogleSignIn}>
            <Text style={styles.largeButtonText}>{strings.googleSignIn}</Text>
          </TouchableOpacity>
          <View>
            <AppleLogin
              clientId="app.coachxapp.com"
              redirectURI="https://app.coachxapp.com/admin-apple-callback"
              scope="name email"
              designProp={{ locale: "ja_JP", scale: 2, type: "sign-in" }}
              responseType="code"
            />
          </View>
        </View>
      </View>
    </View>
  );
}
