import React from "react";
import { View, Text } from "react-native";

export default function AppleCallbackScreen() {
  return (
    <View>
      <Text>AppleCallback</Text>
    </View>
  );
}
