import React from "react";

import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";

import AdminScreen from "../screens/Admin";
import LoginScreen from "../screens/Login";
import SplashScreen from "../screens/Splash";
import ErrorScreen from "../screens/Error";
import SchoolScreen from "../screens/School";
import DownloadScreen from "../screens/Download";
import SchoolCodeScreen from "../screens/SchoolCode";
import AdminAppleCallbackScreen from "../screens/AdminAppleCallback";
import AppleCallbackScreen from "../screens/AppleCallback";
import { strings } from "../resources";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

const Stack = createStackNavigator();

export default function AppNavigator() {
  const linking = {
    prefixes: ["https://app.coachxapp.com/", "http://localhost:19006/"],
    config: {
      screens: {
        Admin: "admin",
        Login: "sign-in",
        School: "school",
        Download: "download",
        SchoolCode: "school-code",
        AppleCallback: "apple-callback",
        Error: "error",
        Splash: "",
      },
    },
  };

  if (window.location.href.includes("apple-callback")) {
    return (
      <Router>
        <Switch>
          <Route path="/admin-apple-callback">
            <AdminAppleCallbackScreen />
          </Route>
        </Switch>
      </Router>
    );
  } else {
    return (
      <>
        <NavigationContainer
          linking={linking}
          fallback={<Text>{strings.loading}</Text>}
        >
          <Stack.Navigator>
            <Stack.Screen
              name="Splash"
              component={SplashScreen}
              options={{ headerShown: false }}
            />
            <Stack.Screen
              name="Login"
              component={LoginScreen}
              options={{ headerShown: false }}
            />
            <Stack.Screen
              name="Admin"
              component={AdminScreen}
              options={{ headerShown: false }}
            />
            <Stack.Screen
              name="School"
              component={SchoolScreen}
              options={{ headerShown: false }}
            />
            <Stack.Screen
              name="Download"
              component={DownloadScreen}
              options={{ headerShown: false }}
            />
            <Stack.Screen
              name="AppleCallback"
              component={AppleCallbackScreen}
              options={{ headerShown: false }}
            />
            <Stack.Screen
              name="SchoolCode"
              component={SchoolCodeScreen}
              options={{ headerShown: false }}
            />
            <Stack.Screen
              name="Error"
              component={ErrorScreen}
              options={{ headerShown: false }}
            />
          </Stack.Navigator>
        </NavigationContainer>
      </>
    );
  }
}
