import React from "react";
import { Text, View, TouchableOpacity } from "react-native";

import { styles } from "../resources";

export default function DownloadScreen({ navigation }) {
  function onAndroid() {
    window.open(
      "https://play.google.com/store/apps/details?id=pestalozzi.tech.coachx"
    );
  }

  function oniOS() {
    window.open("https://apps.apple.com/app/id1516216698");
  }

  return (
    <View style={styles.centerContainer}>
      <Text style={styles.largeTitle}>アプリをダウンロードしてください。</Text>
      <View style={{ marginVertical: 10 }}>
        <TouchableOpacity style={styles.button} onPress={onAndroid}>
          <Text style={styles.buttonText}>Play Store</Text>
        </TouchableOpacity>
      </View>

      <TouchableOpacity style={styles.button} onPress={oniOS}>
        <Text style={styles.buttonText}>App Store</Text>
      </TouchableOpacity>
    </View>
  );
}
